html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
    margin: 0;
    padding: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

  nav ul {
      list-style: none;
  }

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

/* change colours to suit your needs */
ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

/* change colours to suit your needs */
mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

html, body {
    background: #fff;
}

/* -------------------- */

@font-face {
	font-family: century;
	src: url('../public/fonts/century.ttf');
}

@font-face {
	font-family: avenir;
	src: url('../public/fonts/avenir.otf');
}

@font-face {
	font-family: jost;
	src: url('../public/fonts/Jost-VariableFont_wght.ttf');
}

.avenir {
	font-family: avenir;
}

html {
	-webkit-font-smoothing: antialiased;
  background: #fff;
}

/* -------------------- */

body {
  background: #fff;
  color: #000;
  font-family: century, Helvetica, "Helvetica Neue", Arial, sans-serif;
  min-height: 100vh;
}

body.body-noscroll {
  max-height: 100vh;
  overflow: hidden;
}

a {
  color: initial;
  text-decoration: none;
}

.site {
  margin: 0 20px 44px;
  padding-top: 4px;
}

.site-content {
  margin-top: 10px;
}

.container {
  margin: 0 auto;
  max-width: 920px;
}

.safari500 {
  font-weight: 500 !important;
}

/* -------------------- */

.videolist-item {
  background-color: #000;
  display: block;
  margin: 0 0 6px;
  opacity: .95;
  position: relative;
  transition: opacity .2s ease-in-out;
}

.videolist-item-image {
  background: rgba(0,0,0,.3);
  display: block;
  height: auto;
  transition: all .15s ease-in-out;
  width: 100%;
}

.videolist-item-title {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  padding: 0 6px;
  position: absolute;
  right: 0;
  top: 0;
}

.videolist-item-title > span {
  font-family: avenir !important;
  font-weight: 600;
  display: block;
  flex-grow: 1;
  text-align: center;
}

/* -------------------- */

.headertitle {
  float: left;
  overflow: hidden;
  background-image: url(../public/images/logo-n.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: -1px 0 0 -1px;
  opacity: 0.75;
  -webkit-opacity: 0.75;
}

.headertitle,
.headertitle h1 a {
  display: block;
  width: 166px;
  height: 47px;
  cursor: pointer;
}

/* -------------------- */

.menu-ul {
  align-items: start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: -4px 0 0;
  position: relative;
}

.menu-ul-submenu {
  margin: -4px 0 36px;
  margin: -4px 0 clamp(1.25rem, calc(1.25rem + (2.25 * 16 - 1.25 * 16) * ((100vw - 360px) / 620)), 2.25rem);
}

.menu-li {
  display: block;
}

.menu-a {
  color: rgba(0,0,0,.4);
  display: block;
  font-family: jost;
  font-size: clamp(.8rem, calc(.8rem + (2.3 * 16 - .75 * 16) * ((100vw - 360px) / 620)), 2.3rem);
  font-weight: 500;
  margin-left: .3em;
  margin-right: .3em;
  text-align: center;
  text-transform: uppercase;
  transition: color .15s ease-in-out;
}

.menu-a-active,
.menu-a:hover,
.menu-a:focus,
.menu-a:active {
  color: rgba(0,0,0,1);
}

.menu-li:first-child > .menu-a {
  margin-left: 0;
}

.menu-li:last-child > .menu-a {
  margin-right: 0;
}

.menu-ul-ul {
  align-items: start;
  display: none;
  flex-wrap: nowrap;
  justify-content: space-between;
  left: 0;
  margin: 4px 0;
  position: absolute;
}

.menu-ul-ul-active {
  display: flex;
}

.menu-ul-li {
  display: block;
}

.menu-ul-a {
  color: rgba(0,0,0,.4);
  display: block;
  font-family: jost;
  font-size: clamp(.75rem, calc(.75rem + (1.3 * 16 - .75 * 16) * ((100vw - 360px) / 620)), 1.3rem);
  font-weight: 500;
  margin-left: .3em;
  margin-right: .3em;
  text-align: center;
  text-transform: uppercase;
  transition: color .15s ease-in-out;
}

.menu-ul-a-active,
.menu-ul-a:hover,
.menu-ul-a:focus,
.menu-ul-a:active {
  color: rgba(0,0,0,1);
}

.menu-ul-li:first-child > .menu-a {
  margin-left: 0;
}

.menu-ul-li:last-child > .menu-a {
  margin-right: 0;
}

.menu-ul-ul-reel {
  left: clamp(.5rem, calc(.5rem + (1.5 * 16 - .5 * 16) * ((100vw - 360px) / 620)), 1.5rem);
}

.menu-ul-ul-archive {
  left: clamp(2rem, calc(2rem + (11.5 * 16 - 2 * 16) * ((100vw - 360px) / 620)), 11.5rem);
}

.menu-ul-ul-about {
  left: clamp(2rem, calc(2rem + (25 * 16 - 2 * 16) * ((100vw - 360px) / 620)), 25rem);
}

/* -------------------- */

.login-form {
  display: block;
  margin: 0 auto;
  max-width: 320px;
  padding: 80px 0;
}

.login-label {
  display: block;
  font-size: 16px;
}

.login-input {
  border: 1px solid #000;
  display: block;
  font-size: 16px;
  margin: 6px 0;
  padding: 6px;
  width: 100%;
}

.login-submit {
  background: rgba(0,0,0,.75);
  border: 1px solid rgba(0,0,0,.75);
  color: #fff;
  display: inline-block;
  font-size: 16px;
  margin: 6px 0;
  padding: 6px 12px;
  transition: all .15s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}

.login-submit:hover,
.login-submit:active,
.login-submit:focus {
  background: rgba(0,0,0,1);
  border: 1px solid rgba(0,0,0,1);
}

/* -------------------- */

/* -------------------- */

.biography1 {
  clear: both;
  margin-top: 4px;
}

.biography2 {
  clear: both;
  margin-top: 15px;
}

.biography1 p,
.biography2 p {
  font-weight: 500;
  opacity: 0.8;
  -webkit-opacity: 0.8;
  line-height: 115%;
  font-size: 16px;
  text-align: justify;
}

.peteufo {
    width: 180px;
    height: 270px;
    margin-top: 2px;
    margin-bottom: 14px;
    position: relative;
    z-index: 2;
}

.peteufo:hover {
  background-position: 0 100%;
}

.peteufo {
  float: right;
  background-image: url(../public/images/petebio2-3.png);
  margin-left: 15px;
  background-repeat: no-repeat;
}

.pete1 {
  float: left;
  height: auto;
  margin-right: 14px;
  margin-top: 2px;
  margin-bottom: 14px;
  max-width: 180px;
}

/* -------------------- */

table {
  border-collapse: separate;
  border-spacing: 0;
}

.awardstable {
  width: 100%;
  opacity: 0.9;
  -webkit-opacity: 0.9;
}

.awardstable p {
  font-size: 11px;
  text-align: left;
  line-height: 16px;
  margin: 0;
}

.awardstable p strong {
  font-weight: bold;
}

.awardstable .award2 {
  padding-left: 10px;
  max-width: 350px;
  width: 36.57%;
}

.awardstable .award4 {
  padding-right: 2px;
}

.awardstable .award4 p {
  text-align: right;
  margin-right: 7px;
}

/* -------------------- */

.books-wrapper {
  align-items: start;
  display: flex;
  margin-top: 8px;
  max-width: 945px;
}

.books-column-left {
  flex: 1 0 33%;
  margin-right: 20px;
  max-width: 33%;
}

.book-1-cover {
  height: auto;
  max-width: 100%;
}

.books-h1 {
  font-size: 20.3px;
  font-weight: inherit;
  line-height: 18px;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  opacity: 0.8;
  -webkit-opacity: 0.8;
}

.books-p {
  opacity: 0.8;
  -webkit-opacity: 0.8;
  text-align: justify;
  line-height: 115%;
  font-size: 16px;
  margin-bottom: 1em;
}

.books-images-wrapper {
  align-items: start;
  display: flex;
  margin: 20px -10px 0;
}

.book-1-image {
  display: block;
  margin: 0 10px;
  max-width: 25%;
  max-width: calc(25% - 20px);
}

.book-1-image img {
  display: block;
  height: auto;
  width: 100%;
}

/* -------------------- */

.contacttable {
  opacity: 0.85;
  -webkit-opacity: 0.85;
  max-width: 750px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
}

.contacttable p,
.contacttable a {
  line-height: 115%;
  font-size: 16px;
  font-weight: 500;
}

.contacttable .titletext {
  font-family: avenir;
  font-size: 20.3px;
  line-height: 18px;
  margin-bottom: 14px !important;
}

.contacttable .titletext span {
  border-bottom: 1px solid #000;
  font-weight: 500;
}

.contacttable .to_left p {
  text-align: left;
}

.contacttable .to_right p {
  text-align: right;
}

.contacttable strong {
  font-weight: normal;
}

.contacttable em {
  font-style: normal;
}

/* -------------------- */

.gallery {
  line-height: 0;
  -webkit-column-count: 2;
  -webkit-column-gap: 0px;
  -moz-column-count: 2;
  -moz-column-gap: 0px;
  column-count: 2;
  column-gap: 0px;
  margin: 0 -2px;
}

.gallery-item {
  display: block;
  opacity: 0.85;
  padding: 0 2px 4px;
  transition: all .2s ease-in-out;
}

.gallery-item:hover,
.gallery-item:focus,
.gallery-item:active {
  opacity: 1;
}

.gallery-image {
  height: auto;
  max-width: 100%;
  width: 100%;
}

/* -------------------- */

/* -------------------- */

.lightbox {
  background: rgba(0,0,0,.85);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.lightbox-outer {
  height: calc(100vh - 240px);
  margin: 120px 17px;
}

.lightbox-outer.lightbox-outer-video {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lightbox-inner {
  height: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 1480px;
}

.lightbox-inner.lightbox-inner-video {
  max-height: calc(100vw * 0.5622 - 17px * 2);
  width: 100%;
}

.lightbox-inner.lightbox-inner-image {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 120px * 2);
  max-width: calc(100vw - 17px * 2);
}

.lightbox-image {
  max-height: calc(100vh - 120px * 2);
  max-width: calc(100vw - 17px * 2);
}

.lightbox-image-wrapper {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}

.lightbox-close {
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 36px;
  height: 72px;
  line-height: 1em;
  opacity: .9;
  padding: 20px;
  position: absolute;
  text-align: center;
  text-shadow: none;
  transition: all .2s ease-in-out;
  width: 72px;
  z-index: 999;
}

.lightbox-close-video {
  right: -10px;
  top: -72px;
}

.lightbox-close-image {
  right: -20px;
  top: -72px;
}

.lightbox-close:hover,
.lightbox-close:focus,
.lightbox-close:active {
  opacity: .6;
}

/* -------------------- */

.videolist.videolist-5 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.videolist-item-5 {
  margin: 0 3px 6px;
  max-width: calc(50% - 6px);
  min-width: calc(50% - 6px);
}

@media (min-width: 600px) {
  .videolist {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px;
  }

  .videolist-item {
      flex: 1;
      margin: 0 3px 6px;
      max-width: calc(50% - 6px);
      min-width: calc(50% - 6px);
  }

  .videolist-item-5 {
    max-width: calc(33.33% - 6px);
    min-width: calc(33.33% - 6px);
  }

  .videolist-item.videolist-item-latest {
      flex: 1;
      margin: 0 3px 6px;
      max-width: calc(100% - 6px);
      min-width: calc(100% - 6px);
      display: block;
      overflow: hidden;
      max-height: calc(468 / 966 * 100vw);
  }
}

@media (min-width: 768px) {
  .videolist-item {
    opacity: .75;
  }
  
  .videolist-item:hover,
  .videolist-item:active,
  .videolist-item:focus {
    opacity: .95;
  }
  
  .videolist-item-title {
    display: none;
  }

  .videolist-item:hover,
  .videolist-item:active,
  .videolist-item:focus {
    opacity: .95;
  }

  .videolist-item:hover .videolist-item-title,
  .videolist-item:active .videolist-item-title,
  .videolist-item:focus .videolist-item-title {
    display: flex;
  }

  .gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 980px) {
  .videolist-item-2 {
      max-width: calc(50% - 6px);
      min-width: calc(50% - 6px);
  }

  .videolist-item-3 {
    max-width: calc(33.33% - 6px);
    min-width: calc(33.33% - 6px);
  }

  .videolist-item-4 {
    max-width: calc(25% - 6px);
    min-width: calc(25% - 6px);
  }

  .videolist-item-5 {
    max-width: calc(20% - 6px);
    min-width: calc(20% - 6px);
  }

  .videolist-item.videolist-item-latest {
    max-height: calc(468 / 966 * 966px);
    max-width: calc(100% - 6px);
    min-width: calc(100% - 6px);
  }
}

@media (min-width: 1200px) {

  .lightbox-outer {
      height: calc(100vh - 160px);
      margin: 80px 60px;
  }

  .lightbox-inner.lightbox-inner-image {
    max-height: calc(100vh - 80px * 2);
    max-width: calc(100vw - 60px * 2);
  }
  
  .lightbox-image {
    max-height: calc(100vh - 80px * 2);
    max-width: calc(100vw - 60px * 2);
  }

}

@media (min-width: 1280px) {

    .lightbox-outer {
        height: calc(100vh - 240px);
        margin: 120px 80px;
    }

    .lightbox-inner.lightbox-inner-image {
      max-height: calc(100vh - 120px * 2);
      max-width: calc(100vw - 80px * 2);
    }
    
    .lightbox-image {
      max-height: calc(100vh - 120px * 2);
      max-width: calc(100vw - 80px * 2);
    }

    .lightbox-close-video {
      right: -20px;
    }

}

@media (min-width: 1648px) {

  .lightbox-inner.lightbox-inner-video {
      max-height: 892px;
  }

}

/* -------------------- */

@media (max-width: 860px) and (orientation: landscape) {
  .lightbox-outer {
    height: calc(100vh - 100px);
    margin: 50px 17px;
  }

  .lightbox-inner.lightbox-inner-image {
    max-height: calc(100vh - 50px * 2);
    max-width: calc(100vw - 17px * 2);
  }
  
  .lightbox-image {
    max-height: calc(100vh - 50px * 2);
    max-width: calc(100vw - 17px * 2);
  }
}

@media (max-width: 768px) {
  .mobile500 {
    font-weight: 500 !important;
  }

  .pete1 {
    width: 25vw; 
  }

  .peteufo {
    background-size: cover;
    width: 25vw;
    height: calc(1.5 * 25vw);
    z-index: initial;
  }

  .peteufo:hover {
    background-position: 0 0;
  }

  .awardstable tr {
    display: block;
    padding-bottom: 14px;
  }

  .awardstable td {
    margin-bottom: 4px;
  }

  .awardstable p {
    font-size: 11px;
    line-height: 100%;
  }

  .awardstable .award1,
  .awardstable .award2 {
    display: inline-block;
  }
  
  .awardstable .award2 {
    padding-left: 0;
    max-width: none;
    width: initial;
  }

  .awardstable .award3,
  .awardstable .award4 {
    display: block;
    width: 100%;
  }

  .awardstable .award1 {
    font-weight: bold;
    margin-right: 4px;
  }
  
  .awardstable .award4 {
    padding-right: 0;
  }
  
  .awardstable .award4 p {
    text-align: left;
    margin-right: 0;
  }

  .books-wrapper {
    display: block;
  }

  .books-column-left {
    margin-bottom: 16px;
    max-width: none;
  }
  
  .books-images-wrapper {
    display: block;
    margin: 0;
  }
  
  .book-1-image {
    display: block;
    margin: 0 0 14px;
    max-width: none;
  }
}

@media (max-width: 640px) {
  .videolist-archive-music-videos > .videolist-item.videolist-item-5 {
    margin: 0 3px 6px;
    max-width: calc(100% - 6px);
    min-width: calc(100% - 6px);
  }

  .contacttable .to_left,
  .contacttable .to_right {
    display: block;
    width: 100%;
  }

  .contacttable .to_right {
    margin-top: 14px;
  }
  
  .contacttable .to_right * {
    text-align: left !important;
  }
}

/* -------------------- */

.loader {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  border-top: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-left: 6px solid #000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-absolute {
  left: 50%;
  margin: -50px 0 0 -50px;
  position: absolute;
  top: 50%;
}
.loader-white {
  border-top: 6px solid rgba(255, 255, 255, 0.2);
  border-right: 6px solid rgba(255, 255, 255, 0.2);
  border-bottom: 6px solid rgba(255, 255, 255, 0.2);
  border-left: 6px solid #ffffff;
}